import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import feather from 'vue-icon'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)

Vue.config.productionTip = null

Vue.prototype.backend = axios.create({ baseURL: process.env.VUE_APP_API_URL })
// request processing
Vue.prototype.backend.interceptors.request.use(
  config => {
    window.Vue.backload = true
    window.Vue.backerr = false
    config.headers['Authorization'] = "Bearer " + localStorage.getItem('HldmarkAccessToken')
    return config;
  },
  error => {
    window.Vue.backload = null
    window.Vue.backerr = 'Oops! Something went wrong!'
    Promise.reject(error)
  });
// response processing
Vue.prototype.backend.interceptors.response.use(
  response => {
    window.Vue.backload = null
    return response;
  },
  error => {
    window.Vue.backload = null
    // expired or wrong auth
    if (error.response && error.response.status == '401') {
      localStorage.removeItem('HldmarkAccessToken')
      window.location.href = '/login'
    }
    else if (error.response && error.response.data && error.response.data.error)
      {
        // othere errors
        if (error.response.data.description)
         {window.Vue.backerr = 'Error: '+error.response.data.description;}
        else
         {window.Vue.backerr = 'Error: '+error.response.data.error;}
      }
    else
      {window.Vue.backerr = 'Oops! Something went wrong!'}
    
    return Promise.reject(error);
  });

Vue.use(feather, 'v-icon')
window.Vue = new Vue({
  router,
  data: function () {
    return {
      backload: null,
      backerr: null,
    }
  },
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="container-fluid">
  
  <!-- navigation -->
    <nav v-if="logged_in" class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">

      <div class="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" v-for="(r,i) in routes" :key="i">
            <router-link :to="{name: r.name}" v-slot="{href, navigate, isExactActive}">
              <a class="nav-link" :class="{'active':isExactActive}" :href="href" @click="navigate">{{r.name}}</a>  
            </router-link>
          </li>
        </ul>

        <spinner v-show="$parent.backload" />
        <button class="btn btn-sm btn-outline-secondary" @click="logout()" type="button">Logout</button>
        
      </div>
    </nav>
    <div v-else class="col-12 text-center">
      <h1 class="h3 mb-3 font-weight-normal"><img align="bottom" class="mb-4" src="@/images/hldmark_logo.png"> integrator</h1>
      <spinner v-show="$parent.backload" />
    </div>
  
   <!-- Main view -->
   <main role="main" class="container">
     <!-- Loader and alerts -->
    <div class="row justify-content-center">
      <div :class="{'col-12':logged_in,'col-6':!logged_in}" class="text-center">
        
        <b-alert variant="danger" :show="$parent.backerr ? true : false">{{ $parent.backerr }}</b-alert>
      </div>
    </div>
    <router-view @login="check_login" />
  </main>
  </div>
</template>


<script>
import Spinner from '@/components/Spinner.vue'
export default {
  components: {Spinner},
  data(){return{
    links: ["/","/scripts","/officday","/config"],
    logged_in: false
  }},
  computed: {
    routes() {
      return this.$router.options.routes.filter(el => (el.name!='Login' && !el.path.match(/\/.+\//g)))
    }
  },
  methods: {
    logout(){
      localStorage.removeItem('HldmarkAccessToken')
      this.logged_in=false
      this.$router.push("/login")
    },
    check_login(){
      this.backend.get("/status/get").then(resp => {
        if (resp.data) {this.logged_in=true;}
      })
    }
  },
  created() {
    // check if authorization token is correct
    if (!localStorage.getItem('HldmarkAccessToken'))
      {
        this.$router.push("/login")
        return
      }
    this.check_login()
  }
}
</script>

<style>
body {
    min-height: 30rem;
    padding-top: 4.5rem;
    font-size: 0.9rem !important;
}
.icon{
    color:#666;
    width: 15px;
}
.icon:hover{
    color:#000
}
.icon-close{
	color:red
}
.ticons {width: 20px; padding-right: 3px;}
.ticons .red {color: red;}
.ticons .green {color: green;}

.card {margin-bottom: 20px;}

.rowClass td, .rowError td, .rowSuccess td {
  font-size: 0.8em;
  word-break: break-all;
  padding: 0.5rem !important;
}

.rowError td {
  background-color: rgb(253, 235, 235)
}
.rowSuccess td {
  background-color: #c3e6cb;
}

.smallHeader th {
  font-size: 0.8em;
}
</style>
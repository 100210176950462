import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard/Main.vue')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/scripts',
      name: 'Scripts',
      component: () => import(/* webpackChunkName: "scripts" */ './views/Scripts/ScriptsTable.vue')
    },
    {
      path: '/scripts/:script_id',
      name: 'ScriptRun',
      props: true,
      component: () => import(/* webpackChunkName: "script_run" */ './views/Scripts/LastRun.vue'),
      children: [
        {
          path: ':script_run_id',
          name: 'ScriptLog',
          props: true,
          component: () => import(/* webpackChunkName: "script_log" */ './views/Scripts/LogView.vue'),
        }
      ]
    },
    // {
    //   path: '/files',
    //   name: 'Files',
    //   component: () => import(/* webpackChunkName: "files" */ './views/Files/Main.vue'),
    //   children: [{path: ':file_id'}]
    // }
    // ,
    {
      path: '/officeday',
      name: 'Officeday',
      component: () => import(/* webpackChunkName: "officeday" */ './views/Officeday/Main.vue')
    },
    {
      path: '/elko',
      name: 'Elko',
      component: () => import(/* webpackChunkName: "elko" */ './views/Elko/Main.vue')
    },
    {
      path: '/also',
      name: 'Also',
      component: () => import(/* webpackChunkName: "also" */ './views/Also/Main.vue')
    },
    {
      path: '/buiroabc',
      name: 'BuiroABC',
      component: () => import(/* webpackChunkName: "buiroabc" */ './views/BuiroABC/Main.vue')
    },
    {
      path: '/config',
      name: 'Config',
      component: () => import(/* webpackChunkName: "config" */ './views/Config/Main.vue')
    }
  ]
})

// check if authorization token exists, if not redirect to login
router.beforeEach((to, from, next) => {
 if (to.fullPath !== '/login' && !localStorage.getItem('HldmarkAccessToken')) 
    {next('/login');}
 else
    {next();}
});

export default router;